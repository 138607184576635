<template>
  <li class="list-item" :class="[status.split(' ').join('-')]" @click="$event => $emit('click', $event)">
    <h4 class="task-title" :title="workflow.milestoneTitle || 'No milestone'">
      {{ title }}
    </h4>

    <div class="task-due-at">
      By <label :class="checkDueAt(dueAt)">{{ dueAt ? new Date(dueAt).toLocaleDateString() : 'no due date' }}</label>
    </div>

    <hub-reference-list :invention="invention" />

    <div class="task-status">{{ status }}</div>
    <div class="assignees">{{ list }}</div>
    <div class="time">
      {{ timeRepresentation }}
    </div>
  </li>
</template>

<script>
import { differenceInHours, endOfDay, formatDistance } from 'date-fns';

import ReferenceList from '@/components/common/ReferenceList';

export default {
  components: {
    'hub-reference-list': ReferenceList
  },
  props: {
    id: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    dueAt: {
      type: [String, Date],
      default: () => null
    },
    invention: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    assignees: {
      type: Array,
      default: () => []
    },
    statusChangedAt: {
      type: String,
      default: null
    },
    workflow: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['click'],
  data() {
    return {
      timeRepresentation: '',
      timeoutRef: null
    };
  },
  computed: {
    list() {
      if (!this.assignees.length) {
        return 'unassigned';
      }

      return this.assignees.map(a => a.split('@')[0]).join(', ');
    }
  },
  created() {
    this.calculateTime();
    if (this.statusChangedAt) {
      this.timeoutRef = setInterval(this.calculateTime, 60 * 1000);
    }
  },
  unmounted() {
    clearInterval(this.timeoutRef);
  },
  methods: {
    checkDueAt(val) {
      if (!val) {
        return '';
      }

      if (this.status === 'done' || this.status === 'cancelled') {
        return 'default';
      }

      const difference = differenceInHours(endOfDay(new Date(val)), new Date());
      if (difference <= 24) {
        return 'danger';
      }

      if (difference <= 96 && difference > 24) {
        return 'warning';
      }

      if (difference > 96) {
        return 'white';
      }

      return 'warning';
    },
    calculateTime() {
      if (!this.statusChangedAt) {
        this.timeRepresentation = '';
      } else {
        this.timeRepresentation = `${formatDistance(new Date(this.statusChangedAt), new Date())} ago`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-template-rows: max-content max-content;
  grid-gap: 0.25rem;
  line-height: 1;
  background: var(--theme-surface);
  border-radius: 1px;
  font-size: 0.75rem;
  min-height: 1.75rem;
  align-items: center;
  margin-bottom: 0.25rem;

  padding: 0.5rem 0.75rem;
  border: 1px solid transparent;
  transition: 0.25s box-shadow;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: var(--theme-highlight);
    width: 0.25rem;
    left: -1px;
    top: -1px;
    bottom: -1px;
  }

  &.to-do {
    &:before {
      background-color: var(--theme-success);
    }
  }

  &.in-progress {
    &:before {
      background-color: var(--theme-primary);
    }
  }

  &.selectable {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 0 1px var(--theme-highlight);
    }
  }

  &.active {
    box-shadow: 0 0 0 1px var(--theme-highlight);
    background: var(--theme-highlight);
  }

  .task-title {
    font-weight: 700;
    font-size: 0.85rem;
  }

  .task-due-at {
    justify-self: end;
    align-self: start;
    padding-top: 3px;

    label {
      margin-left: 0.25rem;
      font-weight: 500;
      font-size: 0.75rem;
      &.warning {
        color: var(--theme-warning);
      }

      &.danger {
        color: var(--theme-error);
      }

      &.default {
        color: var(--theme-on-background-accent);
      }

      &.white {
        color: var(--theme-on-surface);
      }
    }
  }

  .assignees {
    font-size: 0.75rem;
    .label {
      font-weight: 700;
    }
  }

  .task-status {
    color: var(--theme-primary);
    font-style: italic;
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1.2;
    line-height: 1.2;
    place-self: end;
    text-transform: capitalize;
  }
  .time {
    place-self: end;
    font-size: 0.65rem;
  }
}
</style>
