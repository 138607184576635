<template>
  <ul v-if="total" class="list" :class="{ selectable }" style="overflow-y: scroll">
    <component
      :is="getComponent(layout)"
      v-for="row in collection"
      :key="row.id"
      :class="[...($route.params.id === row.id ? ['active'] : []), ...(selectable ? ['selectable'] : [])]"
      v-bind="row"
      @click="open(row)"
    ></component>
    <li style="display: flex; justify-content: flex-end; padding-right: 1rem">
      <hub-pagination :total="total" :skip="skip" :size="size" @navigate="submit" />
    </li>
  </ul>
  <strong v-else style="display: flex; justify-content: center; padding: 2rem; font-weight: 700">Nothing found</strong>
</template>

<script>
import Pagination from './../common/Pagination';
import TaskCard from './TaskCard';
import TaskCardCompact from './TaskCardCompact';
import TaskCardMinimalistic from './TaskCardMinimalistic';
import TaskCardWithRelativeDueAt from './TaskCardWithRelativeDueAt';
import TaskCardWithAssignee from './TaskCardWithAssignee';
import TaskCardWithAssigneesAndTime from '@/components/search/TaskCardWithAssigneesAndTime';
import TaskCardWithNotes from '@/components/search/TaskCardWithNotes';
import TaskCardWithNotesAndMilestoneTitle from '@/components/search/TaskCardWithNotesAndMilestoneTitle';

export default {
  components: {
    'hub-pagination': Pagination,
    'hub-task-card': TaskCard
  },
  props: {
    collection: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    skip: {
      type: Number,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    layout: {
      type: String,
      default: 'default'
    }
  },
  emits: ['navigate', 'open'],
  methods: {
    open(...args) {
      this.$emit('open', ...args);
    },

    submit(obj) {
      this.$emit('navigate', obj);
    },
    getComponent(layout) {
      switch (layout) {
        case 'compact':
          return TaskCardCompact;
        case 'minimalistic':
          return TaskCardMinimalistic;
        case 'relative':
          return TaskCardWithRelativeDueAt;
        case 'defaultwithassignees':
          return TaskCardWithAssignee;
        case 'defaultwithassigneesandtime':
          return TaskCardWithAssigneesAndTime;
        case 'defaultwithnotes':
          return TaskCardWithNotes;
        case 'defaultwithnotesAndMilestoneTitle':
          return TaskCardWithNotesAndMilestoneTitle;
        default:
          return TaskCard;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  margin: 0;
  padding: 1px;
  list-style: none;

  &:last-child {
    overflow-y: scroll;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
